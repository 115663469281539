<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="教学课堂" style="font-size: 2.5rem;" />
    <!-- 自动追加与下拉刷新列表 -->
    <van-pull-refresh v-model="refreshList.refreshing" @refresh="onRefresh">
      <van-list v-model:loading="refreshList.loading" :finished="refreshList.finished" finished-text="没有更多了"
        @load="onLoad">
        <!-- 九宫格海报列表 -->
        <van-grid :border="false" :column-num="3" class="van-grid">
          <!-- 使用 v-for 循环遍历 gridItems 数组 -->
          <van-grid-item v-for="(item, index) in gridItems" :key="index" @click="openDetails(item)">
            <van-image width="6.3rem" height="10.0rem" :src="item.cover" />
            <!-- 覆盖的 span 标签 -->
            <span class="overlay-text">{{ item.sort }}</span>
            <span class="cover-title">{{ item.name }}</span>
          </van-grid-item>
        </van-grid>
      </van-list>
    </van-pull-refresh>

    <!-- 嵌入子路由组件 -->
    <RouterView></RouterView>

  </div>
</template>


<script lang="ts">
import { ref, reactive, defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { showToast } from 'vant';
import { getHost, ossOdlUrl, ossNewUrl } from '@/api/Api';
import axios from 'axios';



// 定义类型: 分类标签
interface Tab {
  title: string;
}

// 定义类型: 下拉刷新列表
interface RefreshList {
  refreshing: boolean,  // 重刷状态
  loading: boolean,     // 加载状态
  finished: boolean,    // 完毕状态
  title: string,        // 分类标签
  page: number,         // 分页索引
  number: number        // 加载数量
}

// 定义类型: 九宫格列表
interface GridItem {
  sort: string;       // 分类标签
  keymd5: string;     // 短剧标识
  name: string;       // 短剧名称
  materials: string;  // 原始素材
  residue: string;    // 剩余作品
  cover: string;      // 作品封面
  timeu: string;      // 上架时间
}

// 定义类型: 路由跳转新页面
interface GotoPages {
  name: string,
  params: {
    title: string,      // 页面标题
    works: string,      // 作品名称
    sort: string,       // 分类标签
    image: string,      // 封面海报
    keyMd5: string      // 唯一Key
    materials: string;  // 原始素材
    residue: string;    // 剩余作品
    timeu: string;      // 上架时间
  }
}


export default defineComponent({
  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();
    const active = ref(0);
    // 定义一个响应式的数组，用来存放数据
    const refreshList = ref<RefreshList>({
      refreshing: false,
      loading: false,
      finished: false,
      title: '分类标签',
      page: 1,
      number: 20
    });
    const gridItems = ref<GridItem[]>([]);

    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");

      // 获取: 分类下的封面图片
      refreshList.value.title = 'ALL'
      // getSortCover(refreshList.value, true)

    });





    /**
     * 获取: 分类下的封面图片
     * 
     * @param sort    分类标签
     * @param page    页面索引
     * @param number  加载数量
     * @param isEmpty 是否清空
     */
    async function getSortCover(refreshList: RefreshList, isEmpty: boolean) {
      let postMpa = reactive({
        sort: refreshList.title,
        page: refreshList.page,
        number: refreshList.number
      })
      // 将 loading 设置为 true，表示是加载中
      refreshList.loading = true
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=getSortCover", postMpa, {});
        if (isEmpty) {
          gridItems.value = [];
          // 清空列表数据 - 重置加载完毕状态
          refreshList.finished = false;
        }
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code != 0) {
          // api.openMessageBoxAlert('提示：', '登录失败，账户或密码不正确!');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            const items: GridItem[] = JSON.parse(parsedData.data);
            // 逆序添加到 gridItems 中
            for (let i = items.length - 1; i >= 0; i--) {
              items[i].cover = items[i].cover.replace(ossOdlUrl(), ossNewUrl());
              gridItems.value.push(items[i]);
            }
            // 判断是否可能还有剩余数据, 注意只是可能
            if (items.length < refreshList.number) {
              // 可能没有更多数据 - 标记为加载完毕状态
              refreshList.finished = true;
            }
            // 将 loading 设置为 false，表示非加载中
            refreshList.loading = false
            refreshList.page++;
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
          // 加载数据失败 - 标记为加载完毕状态
          refreshList.finished = true;
          // 将 loading 设置为 false，表示非加载中
          refreshList.loading = false
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    /**
     * 事件: 点击了分类标签
     */
    const onClickTab = (tab: Tab) => {
      console.log(`title: ${tab.title}`);
      refreshList.value.page = 1;
      refreshList.value.title = tab.title;
      showToast(tab.title);
      // 获取: 分类下的封面图片
      getSortCover(refreshList.value, true)
    };


    // 点击事件: 打开详情页面
    const openDetails = (obj: GridItem) => {
      console.log(JSON.stringify(obj));

      // 跳转到对应的子页面
      const myPage: GotoPages = {
        name: "aDetails",
        params: {
          title: "作品详情",
          works: obj.name,
          sort: obj.sort,
          image: obj.cover,
          keyMd5: obj.keymd5,
          materials: obj.materials,
          residue: obj.residue,
          timeu: obj.timeu
        }
      };
      // 使用路由跳转页面并传递参数
      routerClass.push(myPage);
    }


    // 点击事件: 触底加载更多作品
    const onLoad = () => {
      if (refreshList.value.page == 1) {
        // 将 loading 设置为 false，表示已经加载完毕
        refreshList.value.loading = false
        return;
      }
      console.log('refreshList: ', refreshList);
      // 获取: 分类下的封面图片
      getSortCover(refreshList.value, false);
    };

    // 点击事件: 下拉刷新作品数据
    const onRefresh = () => {
      refreshList.value.page = 1;
      // 获取: 分类下的封面图片
      getSortCover(refreshList.value, true);
      // 将 refreshing 设置为 false，表示非加载中
      refreshList.value.refreshing = false;
    };




    return {
      active,
      refreshList,
      gridItems,
      onClickTab,
      onLoad,
      onRefresh,
      openDetails,
      getSortCover,
    };
  },
});
</script>


<style>
/* 宫格列表 */
.van-grid {
  padding: 0.5rem;
}


/* 封面的标签 */
.overlay-text {
  position: absolute;
  top: 78%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 文本颜色，可以根据需要修改 */
  color: white;
  /* 文本大小，可以根据需要调整 */
  font-size: 0.4rem;
  /* 半透明背景 */
  background-color: rgba(0, 0, 0, 0.5);
  /* 内边距 */
  padding: 0.2rem 0.2rem;
  /* 圆角边框 */
  border-radius: 0.2rem;
}

/* 封面的标题 */
.cover-title {
  margin-top: 0.3rem;
  font-size: 0.5rem;
  /* 动态宽度 */
  width: 5.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>