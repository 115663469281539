import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "overlay-text" }
const _hoisted_2 = { class: "cover-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_grid_item = _resolveComponent("van-grid-item")!
  const _component_van_grid = _resolveComponent("van-grid")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_van_nav_bar, {
      title: "教学课堂",
      style: {"font-size":"2.5rem"}
    }),
    _createVNode(_component_van_pull_refresh, {
      modelValue: _ctx.refreshList.refreshing,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.refreshList.refreshing) = $event)),
      onRefresh: _ctx.onRefresh
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_list, {
          loading: _ctx.refreshList.loading,
          "onUpdate:loading": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.refreshList.loading) = $event)),
          finished: _ctx.refreshList.finished,
          "finished-text": "没有更多了",
          onLoad: _ctx.onLoad
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_grid, {
              border: false,
              "column-num": 3,
              class: "van-grid"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridItems, (item, index) => {
                  return (_openBlock(), _createBlock(_component_van_grid_item, {
                    key: index,
                    onClick: ($event: any) => (_ctx.openDetails(item))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_image, {
                        width: "6.3rem",
                        height: "10.0rem",
                        src: item.cover
                      }, null, 8, ["src"]),
                      _createElementVNode("span", _hoisted_1, _toDisplayString(item.sort), 1),
                      _createElementVNode("span", _hoisted_2, _toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading", "finished", "onLoad"])
      ]),
      _: 1
    }, 8, ["modelValue", "onRefresh"]),
    _createVNode(_component_RouterView)
  ]))
}