import { createApp } from 'vue'
import App from './App.vue'
import Vant from 'vant';
import {Toast} from 'vant';
import 'vant/lib/index.css'; // 确保 Vant 样式完整引入
import 'amfe-flexible'; // 引入 amfe-flexible 以动态设置 rem 基准值
import router from './router'
import store from './store'

function setRem() {
    const baseSize = 16;
    const designWidth = 375;
    const clientWidth = document.documentElement.clientWidth || window.innerWidth;
    const fontSize = (clientWidth / designWidth) * baseSize;
    document.documentElement.style.fontSize = `${fontSize}px`;
}
window.addEventListener('resize', setRem);
setRem();

createApp(App).use(Vant).use(Toast).use(store).use(router).mount('#app')



