<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="服务在线" style="font-size: 2.5rem;" />
    <!--SVIP 展示-->
    <SvipShow style="cursor: pointer; margin-top: 0.5rem;" :title="svipInfo.title" :subtitle="svipInfo.subtitle"
      :featureText="svipInfo.featureText" :actionText="svipInfo.actionText" @click="onClickGoto('svip')" />
    <!--VIP 展示-->
    <VipShow style="cursor: pointer;" :title="vipInfo.title" :subtitle="vipInfo.subtitle"
      :featureText="vipInfo.featureText" :actionText="vipInfo.actionText" @click="onClickGoto('vip')" />
    <!--为何收费（收费原因）-->
    <div
      style="position: relative; width: 100%; height: 36px; line-height: 36px; background-color: #fff; margin-bottom: 16px;  cursor: pointer;">
      <span style="font-size: 1.0rem; color: #ff0000;" @click="onClickGoto('charge')">本平台为何收费？为什么不能免费？！</span>
    </div>
    <!-- 自动义分割线 -->
    <van-divider dashed :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      style="margin-top: 3.0rem;">客服微信二维码</van-divider>
    <!-- 九宫格海报列表 -->
    <van-grid :border="false" :column-num="3" class="van-grid">
      <!-- 使用 v-for 循环遍历 gridItems 数组 -->
      <van-grid-item v-for="(item, index) in weChatItems" :key="index">
        <van-image width="6rem" height="6rem" :src="item.image" />
        <span style="font-size: 1.0rem; margin: 0.3rem;">{{ item.name }}</span>
      </van-grid-item>
    </van-grid>
    <div style="font-size: 1.1rem; margin-bottom: 200px;">工作时间: 09:00 - 20:00</div>
    <!-- 底部安全区 -->
    <div class="van-safe-area-bottom"></div>
  </div>
</template>




<script lang="ts">
import { ref, reactive, defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { showToast } from 'vant';
import { getHost, ossOdlUrl, ossNewUrl, ResLoginInfo } from '@/api/Api';
import axios from 'axios';
import VipShow from '@/components/VipShow.vue';
import SvipShow from '@/components/SvipShow.vue';





/**
 *  定义类型: SVIP卡显示信息
 */
export interface SvipInfo {
  title: string,        // 普通会员
  subtitle: string,     // VIP暂未开通
  featureText: string,  // 普通主播无限免费试听和导出
  actionText: string,   // 可开通
}


/**
 *  定义类型: VIP卡显示信息
 */
export interface VipInfo {
  title: string,        // 普通会员
  subtitle: string,     // VIP暂未开通
  featureText: string,  // 普通主播无限免费试听和导出
  actionText: string,   // 可开通
}




// 定义类型: 客服信息类
interface WeChatItem {
  name: string,         // 客服名称
  image: string,        // 微信二维码
}



export default defineComponent({
  components: {
    VipShow,
    SvipShow,
  },
  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();
    const active = ref(0);
    // 申明类型: 登录信息接收
    let resLoginInfo = reactive<ResLoginInfo>({
      msg: '消息',
      code: '代码',
      svip: 'SVIP',
      bgClor: '#8a2cdc',
      nick: '昵称',
      level: '级别',
      phone: '账户',
      token: '令牌',
      timee: '时间',
      state: '状态',
    });
    // 申明类型: SVIP卡显示信息
    let svipInfo = reactive<SvipInfo>({
      title: 'SVIP 代理商',
      subtitle: '您暂未开通',
      featureText: '可享受本平台超低价采购',
      actionText: '可办理',
    });
    // 申明类型: VIP卡显示信息
    let vipInfo = reactive<VipInfo>({
      title: 'VIP 高级会员',
      subtitle: '您暂未开通',
      featureText: '每天可以下载 5部 新作品',
      actionText: '可开通',
    });
    const weChatItems: WeChatItem[] = [
      {
        name: '小燕',
        image: require('@/assets/image/weChat1.png')
      },
      {
        name: '小雨',
        image: require('@/assets/image/weChat2.png')
      },
      {
        name: '小婧',
        image: require('@/assets/image/weChat3.png')
      },
    ]


    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");
      onClickInitialization();  // 事件: 初始化
    });


    /**
     * 事件: 初始化
     */
    function onClickInitialization() {
      // 获取本地存储的登录信息
      resLoginInfo.nick = localStorage.getItem('nick') || '';
      resLoginInfo.level = localStorage.getItem('level') || '';
      resLoginInfo.phone = localStorage.getItem('phone') || '';
      resLoginInfo.token = localStorage.getItem('token') || '';
      resLoginInfo.timee = localStorage.getItem('timee') || '';
      resLoginInfo.state = localStorage.getItem('state') || '';
      /*
      console.log('nick: ' + resLoginInfo.nick);
      console.log('level: ' + resLoginInfo.level);
      console.log('phone: ' + resLoginInfo.phone);
      console.log('token: ' + resLoginInfo.token);
      console.log('timee: ' + resLoginInfo.timee);
      console.log('state: ' + resLoginInfo.state);
      */
      if (resLoginInfo.level === '代理服务商') {
        svipInfo.actionText = '已办理';
        svipInfo.subtitle = '您已经开通';
        svipInfo.featureText = '到期时间: ' + resLoginInfo.timee;
        vipInfo.actionText = '已继承';
        vipInfo.subtitle = '您已经继承';
        vipInfo.featureText = '到期时间: ' + resLoginInfo.timee;
      }
      if (resLoginInfo.level === '高级会员') {
        vipInfo.actionText = '已办理';
        vipInfo.subtitle = '您已经开通';
        vipInfo.featureText = '到期时间: ' + resLoginInfo.timee;
      }
    }



    // 处理点击事件的函数
    const onClickGoto = (destination: string): void => {
      console.log(`即将跳转到: ${destination}`);
      if (destination === 'svip') {
        routerClass.push({ name: 'c_as' })
      }
      else if (destination === 'vip') {
        routerClass.push({ name: 'c_bv' })
      }
      else if (destination === 'charge') {
        routerClass.push({ name: 'c_cr' })
      }
    };






    return {
      active,
      vipInfo,
      svipInfo,
      weChatItems,
      onClickGoto,
    };
  },
});
</script>


<style>
/* 宫格列表 - 必须重置，否则有该死的 margin: 1rem */
.van-grid {
  padding: 0.1rem !important;
  margin: 0rem !important;
}
</style>