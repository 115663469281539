import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["poster"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("video", {
      ref: "videoPlayer",
      class: "video-js vjs-default-skin",
      style: _normalizeStyle({ width: _ctx.props.playerWidth, height: _ctx.props.playerHeight }),
      controls: "",
      preload: "none",
      poster: _ctx.props.poster,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.initializePlayer && _ctx.initializePlayer(...args)))
    }, [
      _createElementVNode("source", {
        src: _ctx.src,
        type: "video/mp4"
      }, null, 8, _hoisted_2)
    ], 12, _hoisted_1),
    _renderSlot(_ctx.$slots, "customContent")
  ]))
}