<template>
  <div>
    <video ref="videoPlayer" class="video-js vjs-default-skin"
      :style="{ width: props.playerWidth, height: props.playerHeight }" controls preload="none" :poster="props.poster"
      @click="initializePlayer">
      <source :src="src" type="video/mp4">
    </video>
    <slot name="customContent"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, ref, PropType } from 'vue';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';

export default defineComponent({
  props: {
    src: {
      type: String as PropType<string>,
      required: true
    },
    playerWidth: {
      type: String as PropType<string>,
      default: '500px'
    },
    playerHeight: {
      type: String as PropType<string>,
      default: '900px'
    },
    poster: {
      type: String as PropType<string>,
      default: ''  // 默认没有海报图片
    }
  },
  setup(props) {
    const videoPlayer = ref<HTMLVideoElement | null>(null);
    let player: VideoJsPlayer | null = null;

    // 初始化播放器函数
    const initializePlayer = () => {
      if (videoPlayer.value && !player) {
        player = videojs(videoPlayer.value, {
          controls: true,
          autoplay: true, // 点击后自动播放
          preload: 'auto', // 在点击后加载视频
          bigPlayButton: true,  // 是否显示大播放按钮
          controlBar: {
            children: {
              // 在这里排除 'downloadButton' 控件
              downloadButton: false
            }
          }
        });


        // 监听视频准备事件，确保视频在准备好后播放
        player.ready(() => {
          // 播放视频 + 非空断言
          player!.play();
        });
        // 监听视频播放事件等，传入 player 实例
        videoPlayerEvents(player)
      }
    };

    // 监听各种事件状态
    const videoPlayerEvents = (player: VideoJsPlayer) => {
      player.on('play', () => {
        console.log("视频正在播放...");
      });
      player.on('pause', () => {
        console.log("视频已暂停");
      });
      player.on('ended', () => {
        console.log("视频播放结束");
      });
      player.on('timeupdate', () => {
        // console.log("当前播放时间: ", player!.currentTime());
      });
      player.on('volumechange', () => {
        console.log("音量已更改: ", player!.volume());
      });
      player.on('seeking', () => {
        console.log("正在跳转到新位置...");
      });
      player.on('seeked', () => {
        console.log("跳转完成");
      });
      player.on('loadeddata', () => {
        console.log("视频数据已加载");
      });
      player.on('loadedmetadata', () => {
        console.log("视频元数据已加载");
      });
      player.on('canplay', () => {
        console.log("视频可以播放");
      });
      player.on('canplaythrough', () => {
        console.log("视频已完全加载，可以流畅播放");
      });
      player.on('waiting', () => {
        console.log("等待缓冲...");
      });
      player.on('playing', () => {
        console.log("视频实际开始播放");
      });
      player.on('stalled', () => {
        console.log("视频意外停止加载");
      });
      player.on('error', () => {
        console.error("播放时发生错误: ", player!.error());
      });
      player.on('durationchange', () => {
        console.log("视频时长已改变: ", player!.duration());
      });
      player.on('fullscreenchange', () => {
        console.log("全屏模式已更改");
      });
      player.on('progress', () => {
        console.log("正在加载视频数据...");
      });
      player.on('ratechange', () => {
        console.log("播放速度已更改: ", player!.playbackRate());
      });
      player.on('abort', () => {
        console.log("视频加载已取消");
      });
      player.on('loadstart', () => {
        console.log("浏览器开始加载媒体数据");
      });
      player.on('resize', () => {
        console.log("视频大小已更改");
      });
    };

    onBeforeUnmount(() => {
      if (player) {
        player.dispose();
        player = null;
      }
    });

    // 直接使用 props 的值
    return {
      videoPlayer,
      initializePlayer,
      videoPlayerEvents,
      props // 返回 props 对象， 方便在模板中直接使用
    };
  }
});
</script>

<style>
/* 居中:播放按钮 */
.video-js .vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.video-js .vjs-download-button {
  display: none !important;
}
</style>