<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="登录账号" style="font-size: 2.5rem;" />
    <!-- 会员信息 -->
    <VipInfo width="6.6rem" height="6.6rem" fontSize="1.9rem" :text="resLoginInfo.svip" :bgClor="resLoginInfo.bgClor"
      :name="resLoginInfo.nick" :level="resLoginInfo.level" :phone="resLoginInfo.phone" :timet="resLoginInfo.timee"
      style="margin-top: 0.5rem; padding-bottom: 0.5rem;" />
    <!-- 分割线 -->
    <div style="width: 100%; height: 1.0rem; background-color: #f1f1f1;"></div>
    <!-- 功能区 -->
    <van-cell icon="coupon-o" title="我要升级" is-link v-if="resLoginInfo.svip !== 'SVIP'"
      @click="onClickMyUpgrade('Open')" />
    <van-cell icon="balance-list-o" title="分销统计" is-link v-if="resLoginInfo.svip === 'SVIP'"
      @click="onClickGoto('分销统计')" />
    <van-cell icon="coupon-o" title="卡号管理" is-link v-if="resLoginInfo.svip === 'SVIP'" @click="onClickGoto('卡号管理')" />
    <van-cell icon="video-o" title="作品管理" is-link @click="onClickGoto('作品管理')" />
    <!-- 分割线 -->
    <div style="width: 100%; height: 1rem; background-color: #f1f1f1;"></div>
    <van-cell icon="back-top" title="退出账号" is-link @click="onClickExitLogin" />
    <!-- 分割线 -->
    <div style="width: 100%; height: 1.0rem; background-color: #f1f1f1;"></div>
  </div>
  <!-- 我要升级 - 级别选项卡 -->
  <van-popup closeable v-model:show="myUpgrade.show1" round :style="{ width: '18rem', padding: '1rem' }">
    <div style="font-size: 1rem; font-weight: bold; margin: 1rem;">我要升级为:</div>
    <van-button color="linear-gradient(to right bottom, #ead9fb, #8a2cdc, #ead9fb)"
      style="width: 15rem; height: 3rem; margin-bottom: .8rem;" @click="onClickMyUpgrade('SVIP')">
      代理服务商 SVIP
    </van-button>
    <van-button color="linear-gradient(to right bottom, #fedba0, #ad630c, #fedba0)"
      style="width: 15rem; height: 3rem; margin-bottom: .5rem; " @click="onClickMyUpgrade('VIP')">
      高级会员 VIP
    </van-button>
    <div style="width: 100%; height: 2.0rem; line-height: 2.0rem; color: #0080ff; font-size: 0.6rem;">
      提示: 也可以在原有的VIP基础之上增加到期时长
    </div>
  </van-popup>
  <!-- 我要升级 - 提交卡号 -->
  <van-popup closeable v-model:show="myUpgrade.show2" round :style="{ width: '18rem', padding: '1rem' }">
    <div style="font-size: 1rem; font-weight: bold; margin: 1rem;">在线升级:</div>
    <van-field v-model="myUpgrade.nick" placeholder="请输入您的昵称" maxlength="9" />
    <van-field v-model="myUpgrade.card" rows="2" autosize type="textarea" maxlength="64" placeholder="请输入您购买的升级卡号"
      show-word-limit />
    <van-button type="primary" style="width: 15rem; height: 2rem; margin-bottom: .5rem; "
      @click="onClickUseUpgradeCard">
      立即升级为 {{ myUpgrade.level }}
    </van-button>
  </van-popup>
</template>



<script lang="ts">
import { ref, reactive, defineComponent, onMounted } from 'vue';
import { getHost, reactiveSafeUpdate, ResLoginInfo, clearCookies } from '@/api/Api';
import { showToast, showConfirmDialog } from 'vant';
import VipInfo from '@/components/VipInfo.vue';
import { useRouter } from 'vue-router';
import axios from 'axios';



// 定义类型: 我要升级
interface MyUpgrade {
  show1: boolean,    // 级别选项卡
  show2: boolean,    // 卡号输入框
  level: string,     // 提升级别
  phone: string,     // 升级帐号
  token: string,     // 登录账户
  nick: string,      // 设置昵称
  card: string,      // 升级卡号
}


export default defineComponent({
  components: {
    VipInfo
  },

  setup() {
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();
    let resLoginInfo = reactive<ResLoginInfo>({
      msg: '消息',
      code: '代码',
      svip: 'SVIP',
      bgClor: '#8a2cdc',
      nick: '昵称',
      level: '级别',
      phone: '账户',
      token: '令牌',
      timee: '时间',
      state: '状态',
    });
    let myUpgrade = reactive<MyUpgrade>({
      show1: false,
      show2: false,
      level: '',
      phone: '',
      token: '',
      nick: '',
      card: '',
    });

    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("组件挂载之后执行的方法");
      onClickInitialization();  // 事件: 初始化
    });


    /**
     * 事件: 初始化
     */
    function onClickInitialization() {
      // 获取本地存储的登录信息
      resLoginInfo.nick = localStorage.getItem('nick') || '';
      resLoginInfo.level = localStorage.getItem('level') || '';
      resLoginInfo.phone = localStorage.getItem('phone') || '';
      resLoginInfo.token = localStorage.getItem('token') || '';
      resLoginInfo.timee = localStorage.getItem('timee') || '';
      resLoginInfo.state = localStorage.getItem('state') || '';
      /*
      console.log('nick: ' + resLoginInfo.nick);
      console.log('level: ' + resLoginInfo.level);
      console.log('phone: ' + resLoginInfo.phone);
      console.log('token: ' + resLoginInfo.token);
      console.log('timee: ' + resLoginInfo.timee);
      console.log('state: ' + resLoginInfo.state);
      */
      // 如果登录信息无效 - 则重新登录
      if (resLoginInfo.nick === '' || resLoginInfo.level === '' || resLoginInfo.phone === '' || resLoginInfo.token === '' || resLoginInfo.timee === '' || resLoginInfo.state !== '0') {
        // 使用路由跳转到登录页面
        routerClass.push({ name: 'd_al' });
        return;
      }
      // 登录更新: 用令牌刷新我的资料信息
      refreshAccInfo(resLoginInfo.token, resLoginInfo.phone)
    }

    /**
     * 事件: 我要升级
     */
    function onClickMyUpgrade(tag: string) {
      console.log('我要升级.tag: ' + tag);
      if (tag === 'Open') {
        myUpgrade.show1 = true;
        return;
      }
      if (tag === 'SVIP') {
        myUpgrade.level = tag;
        myUpgrade.show1 = false;
        myUpgrade.show2 = true;
        return;
      }
      if (tag === 'VIP') {
        myUpgrade.level = tag;
        myUpgrade.show1 = false;
        myUpgrade.show2 = true;
        return;
      }
    }


    /**
     * 事件: 使用升级卡
     */
    function onClickUseUpgradeCard() {
      console.log('使用升级卡');
      if (myUpgrade.level === 'SVIP') {
        UseUpgradeCard('useSvipCard');  // 使用 SVIP 升级卡 并 获取新的登录信息
      } else {
        UseUpgradeCard('useVipCard');  //  使用 VIP  升级卡 并 获取新的登录信息
      }
    }



    /**
     * 事件: 跳转页面
     */
    function onClickGoto(tag: string) {
      if (tag === '分销统计') {
        routerClass.push({ name: 'd_dd' });
        return;
      }
      if (tag === '卡号管理') {
        routerClass.push({ name: 'd_bc' });
        return;
      }
      if (tag === '作品管理') {
        routerClass.push({ name: 'd_ew' });
        return;
      }
    }


    /**
     * 事件: 退出登录
     */
    function onClickExitLogin() {
      showConfirmDialog({
        title: '温馨提示',
        message:
          '真的要退出当前账户吗?',
      })
        .then(() => {
          showToast('当前账户已退出');
          // 清空Cookies. 令牌失效需要重新登录
          clearCookies();
          // 使用路由跳转到登录页面
          routerClass.push({ name: 'd_al' });
        })
        .catch(() => {
          console.log("取消");
        });
    }



    /**
     * 使用 SVIP 或 VIP 升级卡 并 获取新的登录信息
     * 
     * @param func SVIP: useSvipCard; VIP: useVipCard; 
     */
    async function UseUpgradeCard(func: string) {
      myUpgrade.phone = resLoginInfo.phone;
      myUpgrade.token = resLoginInfo.token;
      if (myUpgrade.phone.length !== 11 || myUpgrade.token.length !== 64) {
        showToast('请重新登录');
        return;
      }
      if (myUpgrade.card.length !== 64) {
        showToast('升级卡无效');
        return;
      }
      if (myUpgrade.nick.length < 2) {
        showToast('新昵称无效');
        return;
      }
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=" + func, myUpgrade, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code != '0') {
          showToast('发生异常.code: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            reactiveSafeUpdate(resLoginInfo, JSON.parse(parsedData.data));
          } else {
            reactiveSafeUpdate(resLoginInfo, parsedData.data);
          }
          // 如果账户升级成功
          if (resLoginInfo.code === '0') {
            showToast('升级成功');
            myUpgrade.show1 = false;
            myUpgrade.show2 = false;
            localStorage.setItem('nick', resLoginInfo.nick);
            localStorage.setItem('level', resLoginInfo.level);
            localStorage.setItem('phone', resLoginInfo.phone);
            localStorage.setItem('token', resLoginInfo.token);
            localStorage.setItem('timee', resLoginInfo.timee);
            localStorage.setItem('state', resLoginInfo.state);
            onClickInitialization();  // 事件: 初始化
          } else {
            showToast('失败: ' + resLoginInfo.msg);
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    /**
     * 登录更新: 用令牌刷新我的资料信息
     */
    async function refreshAccInfo(token: string, phone: string) {
      let postJson = reactive({
        token: token,
        phone: phone,
      });
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=refreshAccInfo", postJson, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code != '0') {
          showToast('发生异常.code: [' + parsedData.code + ']');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            reactiveSafeUpdate(resLoginInfo, JSON.parse(parsedData.data));
          } else {
            reactiveSafeUpdate(resLoginInfo, parsedData.data);
          }
          // 如果账户信息刷新成功
          if (resLoginInfo.state === '0') {
            localStorage.setItem('nick', resLoginInfo.nick);
            localStorage.setItem('level', resLoginInfo.level);
            localStorage.setItem('phone', resLoginInfo.phone);
            localStorage.setItem('token', resLoginInfo.token);
            localStorage.setItem('timee', resLoginInfo.timee);
            localStorage.setItem('state', resLoginInfo.state);
            if (resLoginInfo.level === '游客') {
              resLoginInfo.svip = '游客';
              resLoginInfo.bgClor = '#f1f1f1';
              return;
            }
            if (resLoginInfo.level === '代理服务商') {
              resLoginInfo.svip = 'SVIP';
              resLoginInfo.bgClor = '#8a2cdc';
              return;
            }
            if (resLoginInfo.level === '高级会员') {
              resLoginInfo.svip = 'VIP';
              resLoginInfo.bgClor = '#f9c21e';
              return;
            }
          } else {
            showToast('账户冻结');
            // 清空Cookies. 令牌失效需要重新登录
            clearCookies();
            // 使用路由跳转到登录页面
            routerClass.push({ name: 'd_al' });
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }




    return {
      resLoginInfo,
      myUpgrade,
      onClickGoto,
      onClickMyUpgrade,
      onClickExitLogin,
      onClickUseUpgradeCard,
    };
  },
});
</script>


<style>
/* 全局设置 */
:root {
  /* 设置全局图标大小为 24px */
  --van-cell-icon-size: 24px;
  --van-cell-font-size: 1.1rem
}
</style>